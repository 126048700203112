.container {
  width: 100%;
  max-width: 400px;
}

.container > * {
  margin-top: 5px;
}

.headlineBox {
  padding-top: 5px;
  border-bottom: solid black 1px;
}
