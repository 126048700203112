.container {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  width: fit-content;
  border-radius: 4px;
  border: solid 1px rgb(196, 196, 196);
  padding: 5px 10px;
  margin-top: 10px;
}

.textContainer {
  width: 450px;
  margin-right: 10px;
}

.textContainer > * {
  margin-top: 10px !important;
}

@media (max-width: 800px) {
  .container {
    flex-direction: column;
    padding: 5px;
    border-radius: 4px;
    margin: 10px 0;
    width: 100%;
  }

  .textContainer {
    max-width: none;
    width: 100%;
    margin-right: 0px;
  }
}
