.card {
  /* width: 30%;
  min-width: 400px;
  max-width: 500px; */
  margin: 10px;
  padding: 0;
}

.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttons {
  width: 100%;
}

.buttons > button {
  margin: 10px 0;
}

.image {
  height: 300px;
  width: 100%;
  object-fit: cover;
}
