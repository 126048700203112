.card {
  width: 100%;
  max-width: 370px;
  min-width: 300px;
  background-color: white;
  margin: 5px;
}

.warning {
  background-color: #fa8c16aa;
}

.error {
  background-color: #f5222dff;
}

.title {
  text-align: center;
}

.actions {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
