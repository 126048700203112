.toolbar {
  display: flex;
  justify-content: space-between;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  height: 50px;
}

@media (max-width: 400px) {
  .image {
    display: none;
  }
}
