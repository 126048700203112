.card {
  margin: 10px;
  /* Not sure why i need important */
  padding: 0px !important;
}

.actions {
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.image {
  height: 300px;
  width: 100%;
  object-fit: cover;
}
