.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* max-width: 600px; */
}

.form > * {
  margin-top: 10px !important;
}

.input {
  max-width: 500px;
}
