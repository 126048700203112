.container {
  width: 100%;
  display: flex;
  align-items: baseline;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: solid 1px gray;
}

.container > div {
  margin-right: 20px;
}

.select {
  width: 200px;
}

@media (max-width: 800px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .container > div {
    margin-right: 0px;
    margin-bottom: 10px;
  }

  .select {
    width: 100%;
  }
}
