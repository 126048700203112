.links {
  text-decoration: none;
  color: inherit;
}

.activeLink {
  color: darkcyan;
}

.listItem {
  color: inherit;
}

.nested {
  padding-left: 40px !important;
}
