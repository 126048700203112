.select {
  width: 100%;
}

.container {
  border-radius: 4px;
  border: solid 1px rgb(196, 196, 196);
  padding: 5px 10px;
  margin-top: 10px;
  max-width: 900px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 50px;
  grid-template-rows: auto;
  grid-template-areas:
    "label label label unit btn"
    "value value quality quality btn"
    "rangeMin rangeMin rangeMax rangeMax btn"
    "limitMin limitMin limitMax limitMax btn"
    "openBtn openBtn openBtn openBtn btn";
}

.container > div {
  margin: 5px;
}

.labelBox {
  grid-area: label;
}

.unitBox {
  grid-area: unit;
}

.valueBox {
  grid-area: value;
}

.qualityBox {
  grid-area: quality;
}

.rangeMinBox {
  grid-area: rangeMin;
}

.rangeMaxBox {
  grid-area: rangeMax;
}

.limitMinBox {
  grid-area: limitMin;
}

.limitMaxBox {
  grid-area: limitMax;
}

.openButton {
  grid-area: openBtn;
  display: flex;
  justify-content: center;
}

.button {
  grid-area: btn;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.hide {
  display: none;
}

@media (max-width: 600px) {
  .container {
    display: flex;
    flex-direction: column;
  }
}
