.controlsContainer {
  width: 100%;
  display: flex;
  align-items: center;
  max-width: 1200px;
}

.backContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid transparent;
}

.backContainer:hover {
  border-bottom: 1px solid black;
}
