.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* max-width: 600px; */
}

.form > * {
  margin-top: 10px !important;
}

.input {
  max-width: 500px;
}

.selectContainer {
  max-width: 500px;
  width: 100%;
}

.select {
  width: 100%;
}

.daysContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 500px;
}

.daysContainer > div {
  margin-right: 10px;
}
