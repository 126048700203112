.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 10%;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  padding: 20px;
}

.loginButton {
  margin-top: 30px !important;
}
