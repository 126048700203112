.container {
  width: 100%;
  padding: 10px;
  /* margin: 10px 0; */
}

.headline {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
}

.labels {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.barOuter {
  position: relative;
  width: 100%;
  border-style: solid;
  border-color: gray;
  border-width: 1px;
  border-radius: 4px;
}

.barInner {
  height: 20px;
}

.barLimit {
  position: absolute;
  top: -5px;
  left: 0px;
  border-right: 3px solid black;
  height: 30px;
}
