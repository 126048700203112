.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: fit-content;
  border-radius: 4px;
  border: solid 1px rgb(196, 196, 196);
  padding: 5px 10px;
  margin-top: 10px;
}
.inputContainer {
  width: 450px;
  max-width: 100%;
}

.inputContainer > div {
  margin: 5px;
}

.select {
  width: 100%;
}

.actionContainer {
  display: flex;
  height: fit-content;
}

@media (max-width: 800px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    margin: 10px 0;
  }

  .selectContainer {
    max-width: none;
    width: 100%;
  }

  .textContainer {
    max-width: none;
    width: 100%;
  }
}
