.card {
  /* background: rgb(121, 203, 228); */
  cursor: pointer;
  width: 300px;
  height: 300px;
  margin: 20px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.card:hover {
  transform: scale(1.03);
}

.iconContainer {
  padding: 0 20px;
  width: 100%;
  max-height: 200px;
}
