.container {
  width: 100%;
  border-bottom: solid black 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container:hover {
  background: rgb(218, 218, 218);
}
