.container {
  width: 100%;
  padding: 5px 10px;
}

.logoWrapper {
  display: flex;
  align-items: center;
}

.image {
  height: 60px;
}
