.container {
  width: 100%;
  max-width: 1400px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 20px;
  align-items: stretch;
}

@media (max-width: 600px) {
  .container {
    width: 100%;
    grid-template-columns: repeat(auto-fill, 100%);
    grid-gap: 20px;
    align-items: stretch;
  }
}
