.contianer {
  width: 100%;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  max-width: 1200px;
  margin-top: 20px;
}

.titleBox {
  width: 100%;
  background-color: #f3f3f3;
  padding: 5px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.content {
  padding: 5px;
}
