.container {
  margin-top: 20px;
  width: 100%;
  max-width: 1200px;
}

.units {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  max-width: 1200px;
  margin-top: 20px;
}
