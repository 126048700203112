.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: solid rgba(128, 128, 128, 0.664) 1px;
}

.btnContainer {
  display: flex;
  align-items: center;
}

@media (max-width: 300px) {
  .row {
    flex-direction: column;
  }
  .btnContainer {
    width: 100%;
    justify-content: space-around;
  }
}
