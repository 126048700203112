.container {
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: transform 0.2s;
}

.container:hover {
  transform: scale(1.03);
}
