.form {
    width: 100%;
    display: flex;
    flex-direction: column;
}
  
.form > * {
    margin-top: 10px !important;
}

.header{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: baseline;
}

.buttons{
    flex:1;
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
}


.input {
    max-width: 500px;
}

.pickerBox{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    max-width: 500px;
}



.datepicker{
    flex-basis: 0;
    flex-grow: 1;
    margin: 5px;
    min-width: 200px;
}