.imgContainer {
  position: relative;
  width: 100%;
  height: 50vh;
  min-height: 400px;
}

.backgroundImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logo {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  max-width: 90vw;
}
