.list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 300px;
  overflow-y: scroll;
}

.logContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
}
