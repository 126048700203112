.card {
    /* width: 30%;
    min-width: 400px;
    max-width: 500px; */
    margin: 10px;
    padding: 0;
    position: relative;
  }
  
  .container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .image {
    height: 300px;
    width: 100%;
    object-fit: cover;
  }
  
  .tempContainer{
      background: rgba(255, 255, 255, 0.9);
      position: absolute;
      top:10px;
      right: 10px;
      width: 100px;
      height: 100px;
      border-radius: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 10px;
  }

  .tempText{
    display: flex;
    
  }

  .waveImage{
    width: 60px;
  }