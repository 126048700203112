.container{
    width: 100%;
    display: flex;
    justify-content: center;
    padding:5px;
    padding-left: 10px;
}

.searchbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    max-width: 500px;
    width: 100%;
    margin-bottom: 20px;
}