.sideDrawer {
  width: 250px;
  flex-shrink: 0;
}

.sideDrawerPaper {
  width: 250px;
}

.toolBar {
}

.drawerControl {
  display: none;
}

.footerContainer {
  width: 100%;

  height: 50px;
  background-color: red;
}

@media (max-width: 800px) {
  .toolBar {
    display: none;
  }
  .drawerControl {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
  }
}
